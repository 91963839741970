import { Identity } from './Identity'
import { ModelBase } from './ModelBase'

export class IdentityStatistics extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.bidsCount = this._num(raw.bidsCount)
    this.wonCount = this._num(raw.wonCount)

    const r11s = raw.relationships || {}
    this.identity = ModelBase.modelFromCache(
      Identity,
      r11s.identity || {},
      cache
    )
  }
}

<template>
  <list-params
    class="analytics-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <list-params-sort
        class="analytics-list-params__row"
        v-model="form.sort"
        :options="sortOptions"
        :default-value="ANALYTICS_DEFAULT_SORT"
      />

      <div class="analytics-list-params__row">
        <label class="list-params-row__label">
          {{ $t('COPART_LOGIN_LBL') }}
        </label>

        <ui-select
          v-model="form.copartLogin"
          fill="frame"
          look="secondary"
          class="analytics-list-params__copart-login"
          :options="managedLoginsOptions"
          :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
          :search-placeholder="$t('COMMON.SELECT_FIELD.SEARCH_PH')"
          has-filter
        />
      </div>

      <list-params-period
        class="analytics-list-params__row"
        :start-date.sync="form.from"
        :end-date.sync="form.to"
        show-icon
        auto-close
      />
    </template>
  </list-params>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import {
  ListParams,
  ListParamsSort,
  ListParamsPeriod
} from 'Common/ListParams'

import { mapGetters } from 'vuex'
import { extensionAnalyticsGetters } from '../store/types'
import { ANALYTICS_SORTS, ANALYTICS_DEFAULT_SORT } from '../constants'

export default {
  name: 'analytics-list-params',
  components: {
    UiSelect,
    ListParams,
    ListParamsSort,
    ListParamsPeriod,
  },

  props: {
    value: { type: Object, required: true },
  },

  data: _ => ({
    form: {
      sort: '',
      copartLogin: '',
      from: '',
      to: '',
    },
  }),

  computed: {
    ...mapGetters('ui/extension-analytics', {
      managedLogins: extensionAnalyticsGetters.MANAGED_LOGINS,
    }),

    sortOptions () {
      return [
        {
          value: ANALYTICS_SORTS.BIDS_COUNT,
          label: this.$t('SORT_BIDS_COUNT_LBL')
        },
        {
          value: ANALYTICS_SORTS.WON_COUNT,
          label: this.$t('SORT_WON_COUNT_LBL')
        },
      ]
    },

    managedLoginsOptions () {
      return this.managedLogins.map(value => ({
        value,
        label: value
      }))
    },

    ANALYTICS_DEFAULT_SORT: () => ANALYTICS_DEFAULT_SORT,
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/ListParams/styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "COPART_LOGIN_LBL": "BY COPART LOGIN",
    "SORT_BIDS_COUNT_LBL": "Bids count",
    "SORT_WON_COUNT_LBL": "Vehicles count"
  },
  "ka": {
    "COPART_LOGIN_LBL": "COPART-ᲖᲔ ᲨᲔᲡᲕᲚᲐ ᲛᲘᲮᲔᲓᲕᲘᲗ",
    "SORT_BIDS_COUNT_LBL": "რაოდენობა",
    "SORT_WON_COUNT_LBL": "მანქანები"
  },
  "ru": {
    "COPART_LOGIN_LBL": "ПО ЛОГИНУ COPART",
    "SORT_BIDS_COUNT_LBL": "Количество ставок",
    "SORT_WON_COUNT_LBL": "Количество авто"
  },
  "uk": {
    "COPART_LOGIN_LBL": "ЗА ЛОГІНОМ COPART",
    "SORT_BIDS_COUNT_LBL": "Кількість ставок",
    "SORT_WON_COUNT_LBL": "Кількість авто"
  }
}
</i18n>

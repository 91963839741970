<template>
  <div class="analytics-item">
    <div class="analytics-cmn__row">
      <dealer-cell
        class="analytics-item__cell"
        :identity="item.identity"
      />

      <component
        :is="isAccountLinkShown ? 'router-link' : 'p'"
        :to="{
          name: 'extension.copart.accounts.entry',
          query: { search: item.identity.copartLogin }
        }"
        class="analytics-item__cell analytics-cmn__str analytics-cmn__str_sec"
        :class="{ 'analytics-cmn__str_link': isAccountLinkShown }"
      >
        <template v-if="item.identity.copartLogin">
          {{ item.identity.copartLogin }}
        </template>

        <template v-else>
          {{ $t('COMMON.NOT_ASSIGNED_MSG') }}
        </template>
      </component>

      <p class="analytics-item__cell analytics-cmn__str analytics-cmn__str_sec">
        <ui-icon
          class="analytics-cmn__ico"
          icon="car-side"
        />

        <span>{{ item.wonCount }}</span>
      </p>

      <p class="analytics-item__cell analytics-cmn__str analytics-cmn__str_sec">
        <ui-icon
          class="analytics-cmn__ico"
          icon="make-a-bid-alt"
        />

        <span>{{ item.bidsCount }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import DealerCell from './DealerCell'

import { IdentityStatistics } from 'Models/IdentityStatistics'
import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'analytics-item',
  components: {
    UiIcon,
    DealerCell,
  },

  props: {
    item: {
      type: IdentityStatistics,
      required: true,
    },
  },

  computed: {
    isAccountLinkShown () {
      return Boolean(this.item.identity.copartLogin) &&
        this.$can(this.$USER_CLAIMS.EXTENSION_ACCOUNTS)
    },

    formattedCountry () {
      return this.item.country
        ? formatLocation(this.item.country)
        : this.$t('COMMON.SYMBOLS.MDASH')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/analytics";

.analytics-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    align-items: center;
    gap: 0.4em;

    &_end {
      justify-self: end;
    }
  }
}
</style>

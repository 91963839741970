<template>
  <div class="analytics">
    <page-subnav class="analytics__subnav">
      <analytics-list-params
        class="analytics__subnav-action"
        :value="{
          sort: $route.query.sort || '',
          copartLogin: $route.query.copartLogin || '',
          from: $route.query.from || '',
          to: $route.query.to || '',
        }"
        @input="onListParamsChange"
      />

      <subnav-search
        class="analytics__subnav-action analytics__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />
    </page-subnav>

    <analytics-list
      class="analytics__list"
      :list="list"
      :load-more="loadMore"
      @update-list-ask="loadList"
      @more-ask="loadMore"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import AnalyticsList from './components/AnalyticsList'
import AnalyticsListParams from './components/AnalyticsListParams'

import { mapActions, mapGetters } from 'vuex'
import { extensionAnalyticsActions, extensionAnalyticsGetters } from './store/types'
import { ANALYTICS_DEFAULT_PERIOD_DAYS } from './constants'

import { showError } from 'Utils/notifications'
import { getDayStartDate, getISODate } from 'Utils/dateHelpers'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

export default {
  name: 'analytics',
  components: {
    PageSubnav,
    SubnavSearch,
    AnalyticsList,
    AnalyticsListParams,
  },

  computed: {
    ...mapGetters('ui/extension-analytics', {
      list: extensionAnalyticsGetters.LIST,
      isLoading: extensionAnalyticsGetters.IS_LOADING,
    }),

    listPayload () {
      return {
        filter: {
          search: this.$route.query.search || undefined,
          copartLogin: this.$route.query.copartLogin || undefined,
          from: this.$route.query.from || undefined,
          to: this.$route.query.to || undefined,
        },
        sort: this.$route.query.sort || undefined,
      }
    }
  },

  async created () {
    this.checkQueryPeriod()
    this.loadManagedLogins()
    await this.loadList()
  },

  methods: {
    ...mapActions('ui/extension-analytics', {
      loadListAction: extensionAnalyticsActions.LOAD_LIST,
      loadMore: extensionAnalyticsActions.LOAD_MORE,
      loadManagedLogins: extensionAnalyticsActions.LOAD_MANAGED_LOGINS,
      setIsLoading: extensionAnalyticsActions.SET_IS_LOADING,
    }),

    checkQueryPeriod () {
      if (this.$route.query.from || this.$route.query.to) return

      const startDate = new Date()
      startDate.setDate(startDate.getDate() - ANALYTICS_DEFAULT_PERIOD_DAYS)

      this.$router.push({
        query: {
          ...this.$route.query,
          from: getISODate(getDayStartDate(startDate))
        }
      })
    },

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.sort = input.sort || undefined
      query.copartLogin = input.copartLogin || undefined
      query.from = input.from || undefined
      query.to = input.to || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    onSearch (str) {
      str = str.trim()
      if ((this.$route.query.search || '') === str) return
      this.setIsLoading(true)
      this.applySearchDebounced(str)
    },

    applySearchDebounced: debounce(async function (search) {
      await this.applySearch(search)
    }, 1000),

    applySearch (str) {
      const query = { ...this.$route.query }
      if ((query.search || '') === str) return
      query.search = str || undefined

      this.$router.push({ query })
      this.loadList()
    },

    async loadList () {
      try {
        await this.loadListAction(this.listPayload)
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.analytics {
  display: flex;
  flex-direction: column;
  flex: 1;
}
</style>

<i18n>
{
  "en": {
    "META_TITLE": "Analytics",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the analytics list. Please try again later or contact the system owner."
  },
  "ka": {
    "META_TITLE": "ანალიტიკა",
    "LIST_FETCH_FAILED_NOTIFY": "ექაუნთები სია ვერ ჩაიტვირთა. მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "META_TITLE": "Аналитика",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список аналитики. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "META_TITLE": "Аналітика",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список аналітики. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>

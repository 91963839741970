export const extensionAnalyticsActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_MORE: 'LOAD_MORE',
  LOAD_MANAGED_LOGINS: 'LOAD_MANAGED_LOGINS',
  SET_IS_LOADING: 'SET_IS_LOADING',
}

export const extensionAnalyticsMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST_OPTS: 'SET_LIST_OPTS',
  SET_LIST: 'SET_LIST',
  PUSH_LIST: 'PUSH_LIST',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_MANAGED_LOGINS: 'SET_MANAGED_LOGINS',
}

export const extensionAnalyticsGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  LIST_OPTS: 'LIST_OPTS',
  PAGE_NUMBER: 'PAGE_NUMBER',
  MANAGED_LOGINS: 'MANAGED_LOGINS',
}
